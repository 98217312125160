import React from "react";
import firstbg from "../assets/_bg.png";
import iphone from "../assets/iphone.png";
import appleStore from "../assets/applestore.png";
import googleStore from "../assets/googlestore.png";
import fomo from "../assets/fomo.png";
import fiatMoney from "../assets/fiat-money.png";
import encrypted from "../assets/encrypted.png";
import cryptocurrencyWallet from "../assets/cryptocurrency-wallet.png";
import section4bg from "../assets/bg12.png";
import ninetGrid from "../assets/ninetgrid.png";
import eth from "../assets/eth.png";
import section5bg from "../assets/bg09.png";
import line from "../assets/line.png";
import section from "../assets/section.png";
import human from "../assets/human.png";
import whiteElf from "../assets/white_elf.png";
import halfDragon from "../assets/half_dragon.png";
import dwarf from "../assets/dwarf.png";
import elfF from "../assets/elf_f.png";
import oakF from "../assets/oak_f.png";

import { useTranslation } from "react-i18next";

const Section1 = () => {
  const { t } = useTranslation();
  const appleStoreLink = "https://apps.apple.com/us/app/quarkto-wallet/id1623769299";
  const googleStoreLink = "https://play.google.com/store/apps/details?id=com.sourcekode.quarkto";

  return (
    <div className="pt-20">
      <div
        className="relative  w-full"
        style={{
          backgroundImage: `url(${firstbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className=" container mx-auto px-4">
          <div className="grid grid-row-1 lg:grid-cols-12 gap-7 items-center h-full ">
            <div className="order-2 lg:order-1 lg:col-span-8 mx-auto lg:mx-0">
              <div className="text-3xl  lg:text-5xl lg:leading-normal font-semibold ">
                <div>Quarkto Wallet</div>
                <div>{t("section1.title1")}</div>
              </div>

              <div className="mt-6 text-base text-[#6D747C] font-medium">
                <div className=" w-full lg:w-7/12">{t("section1.title2")}</div>
              </div>
              <div className="mt-10 flex flex-wrap gap-4">
                <a href={appleStoreLink} target="_blank"><img className="" src={appleStore} alt="appleStore" /></a>
                <a href={googleStoreLink} target="_blank"><img className="" src={googleStore} alt="googleStore" /></a>
              </div>
            </div>
            <div className="order-1 lg:order-2 lg:col-span-4">
              <img className="mx-auto" src={iphone} alt="iphone" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section2 = () => {
  const { t } = useTranslation();
  const section2List = [
    {
      key: "fomo",
      img: fomo,
      title: t("section2.title1"),
      content: t("section2.description1"),
    },
    {
      key: "fiatMoney",
      img: fiatMoney,
      title: t("section2.title2"),
      content: t("section2.description2"),
    },
    {
      key: "encrypted",
      img: encrypted,
      title: t("section2.title3"),
      content: t("section2.description3"),
    },
    {
      key: "cryptocurrencyWallet",
      img: cryptocurrencyWallet,
      title: t("section2.title4"),
      content: t("section2.description4"),
    },
  ];

  const listDom = section2List.map((item) => (
    <div
      key={item.key}
      className=" box-border border rounded-[20px] border-[#DCDCE7] p-6 lg:p-0 lg:pt-[40px] lg:px-[40px] lg:pb-4"
    >
      <div className=" flex ">
        <>
          <img className="h-full mr-6" src={item.img} alt={item.key} />
        </>
        <div>
          <div className=" text-base lg:text-xl font-semibold ">
            {item.title}
          </div>

          <div className=" text-sm lg:text-base  text-[#6D747C] mt-3 font-medium">
            {item.content}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className=" flex items-center justify-center py-32">
      <div className="">
        <div className="flex justify-center text-[40px] font-semibold px-6 text-center">
          Why choose our Wallet
        </div>
        <div className="flex justify-center">
          <div className="border-2 border-black w-24"></div>
        </div>

        <div className="mt-20 container  mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 px-6">
            {listDom}
          </div>
        </div>
      </div>
    </div>
  );
};

const Section3 = () => {
  const { t } = useTranslation();
  const section3List = [
    {
      title: t("section3.title1"),
      content: t("section3.description1"),
    },
    {
      title: t("section3.title2"),
      content: t("section3.description2"),
    },
    {
      title: t("section3.title3"),
      content: t("section3.description3"),
    },
    {
      title: t("section3.title4"),
      content: t("section3.description4"),
    },
    {
      title: t("section3.title5"),
      content: t("section3.description5"),
    },
    {
      title: t("section3.title6"),
      content: t("section3.description6"),
    },
  ];

  const listDom = section3List.map((item) => (
    <div
      key={item.title}
      className="box-border border rounded-[20px] border-[#DCDCE7] pt-5 pb-6 px-6 overflow-ellipsis break-words"
    >
      <div>
        <div className=" text-base lg:text-xl font-semibold">{item.title}</div>

        <div className="text-sm lg:text-[18px]  text-[#6D747C] mt-3 font-medium">
          {item.content}
        </div>
      </div>
    </div>
  ));
  return (
    <div
      id="section3"
      className="py-20 w-full"
      style={{
        background:
          "linear-gradient(192.26deg, rgba(30, 58, 205, 0.04) 6.53%, rgba(247, 247, 247, 0) 18.35%)",
      }}
    >
      <div className="flex justify-center text-[40px] font-semibold">
        Assets
      </div>
      <div className="flex justify-center">
        <div className="border-2 border-black w-24"></div>
      </div>
      <div className=" mt-8 flex justify-center text-base text-[#6D747C] font-medium text-center px-6">
        {t("section3.mainDescription")}
      </div>

      <div className="mt-20 container mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-7 px-6">
          {listDom}
        </div>
      </div>
    </div>
  );
};

const Section4 = () => {
  const { t } = useTranslation();
  return (
    <div className="" id="section4">
      <div
        className="flex justify-center py-32"
        style={{
          backgroundImage: `url(${section4bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className=" container mx-auto flex-row lg:flex justify-between items-center px-7">
          <div className="pt-72 lg:pt-0">
            <div className="text-[28px] font-semibold">NFTs</div>
            <div className="mt-3 text-base text-[#6D747C] font-medium w-3/4">
              {t("section4.description")}
            </div>
            <div className="px-14 mt-20">
              <div className="border rounded-[20px] border-[#DCDCE7] w-[100px] p-4">
                <img src={eth} alt="eth"></img>
              </div>
              <div className="text-xl font-semibold mt-6">Ethereum (ETH)</div>
            </div>
          </div>
          <div className=" mt-10 lg:mt-0">
            <img className="mx-auto" src={ninetGrid} alt="ninetGrid"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section5 = () => {
  const { t } = useTranslation();
  const simpleStep = [
    {
      title: t("section5.title1"),
      content: t("section5.description1"),
    },
    { title: t("section5.title2"), content: t("section5.description2") },
    {
      title: t("section5.title3"),
      content: t("section5.description3"),
    },
  ];

  return (
    <div
      className="h-[770px]"
      style={{
        backgroundImage: `url(${section5bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div className="flex justify-center text-4xl lg:text-[40px] font-semibold text-center ">
        Get started in 3 simple steps
      </div>
      <div className="flex justify-center">
        <div className="border-2 border-black w-24"></div>
      </div>

      <div className="mt-[70px] mx-auto relative ">
        <img className="absolute w-0.5 left-1/2 top-0 " src={line} alt="line" />

        {simpleStep.map((i, index) => (
          <div
            key={i.title}
            className="w-11/12 mx-auto flex justify-center  pt-16"
          >
            <div className="w-1/2 pr-8">
              {index % 2 !== 0 ? (
                <div className="flex flex-col items-end text-right">
                  <p className=" text-base lg:text-xl font-medium">{i.title}</p>
                  <p className="border-t-4 border-black w-10"></p>
                  <p className="text-sm lg:text-base text-[#6D747C] font-normal mt-4 w-full lg:w-[330px]">
                    {i.content}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="flex-none w-6 h-6 lg:w-10 lg:h-10">
              <img
                className="w-full h-full relative z-10"
                src={section}
                alt="section"
              />
            </div>

            <div className="w-1/2 pl-4 lg:pl-8">
              {index % 2 === 0 ? (
                <div>
                  <p className=" text-base lg:text-xl font-medium">{i.title}</p>
                  <p className="border-t-4 border-black w-10"></p>
                  <p className=" text-sm lg:text-base text-[#6D747C] font-normal mt-4 w-full lg:w-[330px]">
                    {i.content}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}

        {/* <div className=" absolute transform left-1/2 ">
          <img className="absolute" src={line} alt="alt" />

          <div className="flex-row pt-16">
            <div className="relative flex -left-[15px]">
              <div>
                <img className="mr-6" src={section} alt="section" />
              </div>

              <div className="">
                <div className=" text-xl font-medium">
                  Download Quarkto Wallet
                </div>
                <div className="border-t-4 border-black w-10">


                </div>
                <div className="text-base text-[#6D747C] font-normal mt-4 w-[330px]">Quarkto Wallet have iOS and android version app.</div>
              </div>
            </div>
            <div className="relative flex -left-[15px] ">
              <div>
                <img className="mr-6" src={section} alt="section" />
              </div>

              <div className="">
                <div className=" text-xl font-medium">
                  Download Quarkto Wallet
                </div>
                <div className="border-t-4 border-black w-10">


                </div>
                <div className="text-base text-[#6D747C] font-normal mt-4 w-[330px]">Quarkto Wallet have iOS and android version app.</div>
              </div>
            </div>
          </div>


        </div> */}
      </div>
    </div>
  );
};

const Section6 = () => {
  const { t } = useTranslation();
  const memberList = [
    {
      name: t("section6.title1"),
      sex: t("section6.sex1"),
      img: human,
    },
    {
      name: t("section6.title2"),
      sex: t("section6.sex2"),
      img: elfF,
    },
    {
      name: t("section6.title3"),
      sex: t("section6.sex3"),
      img: oakF,
    },
    {
      name: t("section6.title4"),
      sex: t("section6.sex4"),
      img: dwarf,
    },
  ];

  const listDom = memberList.map((item) => (
    <div
      key={item.name}
      className=" bg-white rounded-[20px]  w-[240px] lg:w-full mx-auto"
    >
      <img className="w-full" src={item.img} alt="item.name"></img>
      <div className="px-[22px] mt-4 mb-3">
        <p className=" texft-xl font-semibold"> {item.name}</p>
        <p className=" text-base text-[#6D747C] font-normal mt-2">
          {" "}
          {item.sex}
        </p>
      </div>
    </div>
  ));

  return (
    <div
      id="section6"
      className="py-[100px] px-20"
      style={{
        background:
          "linear-gradient(268.19deg, rgba(48, 56, 102, 0.08) 0.89%, rgba(247, 247, 247, 0) 82.18%)",
      }}
    >
      <div className=" mx-auto">
        <div className="flex  justify-center text-center ">
          <div>
            <div className=" font-semibold text-[40px]">Quarktoverse</div>
            <div className="flex  justify-center mt-2">
              <div className="w-[106px] border-t-4 border-[#002246]"></div>
            </div>
            <div className=" font-medium text-base mx-auto text-[#6D747C] w-full lg:w-3/5 mt-8">
              {t("section6.mainDescription")}
            </div>
          </div>
        </div>
        <div className=" container mx-auto grid grid-cols-1 lg:grid-cols-4  gap-[30px]  mt-16">
          {listDom}
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  return (
    <div className="">
      {Section1()}
      {Section2()}
      {Section3()}
      {Section4()}
      {Section5()}
      {Section6()}
    </div>
  );
};

export default HomePage;
