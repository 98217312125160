import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./views/HomePage";
import { firebaseConfig } from "./firebase.config";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header></Header>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer></Footer>
      </div>
    </BrowserRouter>
  );
}

const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp); 

export default App;
