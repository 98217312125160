import { React, useState } from "react";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import option from "../assets/option.png";

const Header = () => {
  const { t, i18n } = useTranslation();

  const menuList = [
    { label: "EN", value: "en" },
    { label: "中文", value: "zh-TW" },

  ];

  const listDom = menuList.map((item) => (
    <option key={item.label} value={item.value}>
      {item.label}
    </option>
  ));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };



  return (
    <div className="fixed w-screen  z-50 bg-white">


      <div className="  container mx-auto py-4  lg:py-7 px-4 ">
        <div className="flex  items-center justify-between">
          <div>

            <img className=" w-40 lg:w-auto" src={logo} alt="logo" />{" "}
          </div>
          <div className="hidden lg:block">
            <div className="flex text-sm font-medium ">
              <Link to="section3" smooth={true}>
                <button className="mr-12 ">{t("banner.menu1")}</button>
              </Link>
              <Link to="section4" smooth={true}>
                <button className="mr-12">{t("banner.menu2")}</button>
              </Link>
              <Link to="section6" smooth={true}>
                <button className="mr-7">{t("banner.menu3")}</button>
              </Link>
              <select
                className="focus:outline-none"
                onChange={(e) => changeLanguage(e.target.value)}
              >
                {listDom}
              </select>
            </div>
          </div>
          <div className="block lg:hidden">
            <select
              className="focus:outline-none p-0 mr-2"
              onChange={(e) => changeLanguage(e.target.value)}
            >
              {listDom}
            </select>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img className="w-6 h-6" src={option} alt="option" />
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Link to="section3" smooth={true}>
                <MenuItem onClick={handleClose}>

                  <button className="text-center">{t("banner.menu1")}</button>

                </MenuItem>
              </Link>
              <Link to="section4" smooth={true}>
                <MenuItem onClick={handleClose}>

                  <button >{t("banner.menu2")}</button>

                </MenuItem>
              </Link>
              <Link to="section6" smooth={true}>
                <MenuItem onClick={handleClose}>

                  <button >{t("banner.menu3")}</button>

                </MenuItem>
              </Link>

            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
