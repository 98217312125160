import React from "react";


const Footer = () => {


  return (
    <div className=" bg-[#283445]  ">
      <div className=" px-20 py-9">
        <div className="border border-[#DCDCE766] border-opacity-40"></div>
        <div className="mt-4 flex-row lg:flex justify-between text-[#F6F6F6] text-base font-normal text-center">
          <div className="flex justify-center">
            <div className="mr-7">使用者條款</div>
            <div className="">隱私權政策</div>
          </div>
          <div>Copyright © 2022. All rights reserved by DragonRiver Co.. Ltd.</div>
          <a href="https://www.CryptoDragonRiver.com" target="_blank" rel="noopener noreferrer">www.CryptoDragonRiver.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
